import {
  useCreateAsset,
  useMarkAssetAsUploaded,
  useUploadToPresignedUrl,
} from "@api/asset";
import { useSubmitApplication, useUpdateUser } from "@api/user";
import { AssetPurpose, AssetType } from "@interfaces/asset";
import { IUpdateUser } from "@interfaces/user";
import useUserStore from "@stores/user";
import { useEffect, useRef } from "react";

import { useErrorStore } from "@/stores/error";

const dataURLtoFile = (url: string, filename: string): File => {
  if (!url) return new File([], filename);
  const arr = url.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const useSubmit = () => {
  const { mutate, isError, isSuccess } = useSubmitApplication();
  const {
    mutate: updateUser,
    isError: updateUserError,
    isSuccess: updateUserSuccess,
  } = useUpdateUser();
  const { mutate: createAsset, data } = useCreateAsset();
  const {
    mutate: uploadToPresignedUrl,
    isSuccess: uploadToPresignedUrlSuccess,
  } = useUploadToPresignedUrl();
  const { mutate: markAssetAsUploaded, isSuccess: markAssetAsUploadedSuccess } =
    useMarkAssetAsUploaded();

  const user = useUserStore((state) => state.user);
  const instaHandle = useUserStore((state) => state.instaHandle);
  const setUser = useUserStore((state) => state.setUser);
  const photo = useUserStore((state) => state.photo);
  const { setError, setIsAPIError } = useErrorStore();
  const prevDataRef = useRef(null);

  const handleSubmit = () => {
    const asset = {
      purpose: AssetPurpose.PROFILE_PICTURE,
      type: AssetType.IMAGE,
    };
    createAsset(asset);
  };

  const handleSubmitWithoutPhoto = () => {
    const updateUserData: IUpdateUser = {
      name: user?.name ?? "",
      date_of_birth: new Date(user?.date_of_birth ?? "")
        .toISOString()
        .split("T")[0],
      gender: user?.gender ?? null,
      profile_image_url: user?.profile_image_url ?? "",
      extras_json: {
        "instagram_handle": instaHandle,
      },
    };

    updateUser(updateUserData);
  }

  useEffect(() => {
    if (updateUserError) {
      setError("Error updating user", "Please try again after some time.");
      setIsAPIError(true);
    }
    if (updateUserSuccess) {
      mutate();
    }
  }, [updateUserError, updateUserSuccess, mutate, setError, setIsAPIError]);

  useEffect(() => {
    if (isError) {
      setError(
        "Error submitting application",
        "Please try again after some time.",
      );
      setIsAPIError(true);
    }
  }, [isError, setError, setIsAPIError]);

  useEffect(() => {
    if (data && !prevDataRef.current) {
      const photoFile = dataURLtoFile((photo as string) ?? "", "profile.jpg");
      const url = data?.assets[0]?.presigned_url;
      setUser({ ...user, profile_image_url: url.split("?")[0] });
      uploadToPresignedUrl({ url, file: photoFile });
    }
    prevDataRef.current = data;
  }, [data, uploadToPresignedUrl, photo, setUser, user, updateUser]);

  useEffect(() => {
    if (uploadToPresignedUrlSuccess) {
      markAssetAsUploaded(data?.assets[0]?.asset?.id);
    }
  }, [uploadToPresignedUrlSuccess, data, markAssetAsUploaded]);

  useEffect(() => {
    if (markAssetAsUploadedSuccess && !updateUserSuccess) {
      const updateUserData: IUpdateUser = {
        name: user?.name ?? "",
        date_of_birth: new Date(user?.date_of_birth ?? "")
          .toISOString()
          .split("T")[0],
        gender: user?.gender ?? null,
        profile_image_url: user?.profile_image_url ?? "",
        extras_json: {
          "instagram_handle": instaHandle,
        },
      };

      updateUser(updateUserData);
    }
  }, [markAssetAsUploadedSuccess, updateUser, user, instaHandle, updateUserSuccess]);

  return { handleSubmit, success: isSuccess , handleSubmitWithoutPhoto };
};

export default useSubmit;
