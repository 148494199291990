import { useGetUser } from "@api/user";
import useUserStore from "@stores/user";
import { useEffect } from "react";

const useFetchUser = () => {
  const { data, isError, isLoading, isSuccess, refetch, error } = useGetUser();
  const { setUser } = useUserStore();

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setUser(data);
    }
  }, [data, isLoading, isError, setUser]);

  return { isLoading, isSuccess, refetch, isError, error };
};

export default useFetchUser;
