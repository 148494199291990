import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

const Spin: React.FC = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      background="black"
    >
      <Spinner size="xl" maxWidth="100%" maxHeight="100%" color="gray" />
    </Box>
  );
};

export default Spin;
