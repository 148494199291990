import bgImage from "@assets/bg_form.png";
import { Box, VStack, Fade } from "@chakra-ui/react";
import Form from "@components/Form";
import HotspotDetails from "@components/HotspotDetails";
import useSubmit from "@hooks/useSubmit";
import { WaitlistStatus } from "@interfaces/user";
import useUserStore from "@stores/user";
import React, { useEffect } from "react";

import PrimaryCTA from "@/buttons/PrimaryCTA";

interface OnboardingFormProps {
  setStage: (stage: number) => void;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({ setStage }) => {
  const [isFormValid, setIsFormValid] = React.useState(false);
  const { handleSubmit, success, handleSubmitWithoutPhoto } = useSubmit();
  const setStatus = useUserStore((state) => state.setStatus);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (success) {
      setStatus(WaitlistStatus.WAITLIST);
      setStage(4);
    }
  }, [success, setStage, setStatus]);

  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="black"
      bgImage={bgImage}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      overflowY="auto"
      sx={{
        scrollbarWidth: "none",
      }}
    >
      <VStack mx="1rem" pt="2rem">
        <HotspotDetails isWaitlistPage />
      </VStack>
      <Form setFormValid={setIsFormValid} />
      <Box position="absolute" bottom="0" width="100%">
        <Fade in={isFormValid}>
          <Box bg="black" p="1rem" display="flex" justifyContent="center">
            <PrimaryCTA
              text="Submit"
              onClick={
                user?.profile_image_url
                  ? handleSubmitWithoutPhoto
                  : handleSubmit
              }
              display={isFormValid}
            />
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

export default OnboardingForm;
