import verified from "@assets/verified.png";
import {
  Container,
  HStack,
  Image,
  Text,
  Flex,
  Divider,
  VStack,
} from "@chakra-ui/react";
import Point from "@components/Point";
import useHotspotStore from "@stores/hotspot";
import dayjs from "dayjs";
import { MapPin, CalendarCheck, Users } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

import AcceptInviteButton from "@/buttons/AcceptInviteButton";
import InterestedButton from "@/buttons/InterestedButton";

function formatDateString(dateString: string): string {
  const date = dayjs(dateString);
  const day = date.format("D");
  const month = date.format("MMM");
  const year = date.format("YY");
  return `${day} ${month}'${year}`;
}

interface HotspotCardProps {
  setStage?: (stage: number) => void;
  showInterestedButton?: boolean;
}

const HotspotCard: React.FC<HotspotCardProps> = ({
  setStage,
  showInterestedButton = false,
}) => {
  const hotspot = useHotspotStore((state) => state.hotspot);
  const validity = useHotspotStore((state) => state.validity);
  const stackRef = useRef<HTMLDivElement>(null);
  const [stackHeight, setStackHeight] = useState(0);
  const numberOfBlurDivs = 20;
  const heightDelta = stackHeight / numberOfBlurDivs;
  const blurDivs = Array(numberOfBlurDivs).fill(null);

  const buttonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setStage?.(2);
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === stackRef.current) {
          setStackHeight(entry.contentRect.height);
        }
      }
    });

    if (stackRef.current) {
      resizeObserver.observe(stackRef.current);
    }
    setTimeout(() => {
      if (stackRef.current) {
        setStackHeight(stackRef.current.clientHeight);
      }
    }, 100);
    const cleanUpNode = stackRef.current;
    return () => {
      if (cleanUpNode) {
        resizeObserver.unobserve(cleanUpNode);
      }
    };
  }, []);

  return (
    <Container
      bgImage={hotspot?.banner_image_url}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      borderRadius="0.75rem"
      borderBottom="1px solid"
      display="flex"
      justifyContent="center"
      w="auto"
      h="35rem"
      position="relative"
      pt="1rem"
      px="0rem"
      mx="1rem"
      onClick={() => setStage?.(1)}
    >
      <VStack alignItems="center" w="100%">
        <Container
          borderRadius="0.5rem"
          py="1rem"
          backgroundColor="#10101080"
          backdropFilter="auto"
          width="fit-content"
          display="inline-flex"
          backdropBlur="8px"
          position="absolute"
          top="1rem"
        >
          <HStack spacing={2} alignItems="center">
            <Point isRed={!validity} />
            <HStack spacing={2} ml={1}>
              <Flex alignItems="center">
                <Users size={12} color="white" />
                <Text fontWeight="400" color="white" ml={1} fontSize="xs">
                  {hotspot?.capacity}
                </Text>
              </Flex>
              <Divider
                orientation="vertical"
                height="12px"
                borderColor="white"
              />
              <Flex alignItems="center">
                <CalendarCheck size={12} color="white" />
                <Text fontWeight="400" color="white" ml={1} fontSize="xs">
                  {formatDateString(hotspot?.start_datetime ?? "")}
                </Text>
              </Flex>
              <Divider
                orientation="vertical"
                height="12px"
                borderColor="white"
              />
              <Flex alignItems="center">
                <MapPin size={12} color="white" />
                <Text fontWeight="400" color="white" ml={1} fontSize="xs">
                  {hotspot?.address.address_line_1}
                </Text>
              </Flex>
            </HStack>
          </HStack>
        </Container>
        {blurDivs.map((_, index) => (
          <Container
            key={index}
            position="absolute"
            bottom="0"
            width="100%"
            height={`${stackHeight - index * heightDelta}px`}
            backgroundColor="rgba(16,16,16,0.1)"
            backdropFilter="auto"
            borderRadius="0.75rem"
            backdropBlur={`${1}px`}
            zIndex={19 - index}
          />
        ))}
        <VStack
          ref={stackRef}
          width="100%"
          h="auto"
          px="1rem"
          pb="1rem"
          alignItems="center"
          position="absolute"
          bottom="0rem"
          pt="4rem"
          zIndex={20}
        >
          <HStack spacing={2}>
            <Image
              src={hotspot?.host.user.profile_image_url}
              boxSize="24px"
              borderRadius="full"
            />
            <Flex alignItems="center">
              <Text color="white" fontSize="sm" fontWeight="700">
                {hotspot?.host?.user.name}
              </Text>
              <Image src={verified} boxSize="12px" ml="2px" />
              <Text fontSize="sm" fontWeight="400" color="#FFFFFF7A" ml={1}>
                is hosting
              </Text>
            </Flex>
          </HStack>
          <Text
            fontFamily="titan"
            color="white"
            fontSize="2rem"
            textAlign="center"
          >
            {hotspot?.name}
          </Text>
          <Text
            color="white"
            textAlign="center"
            fontWeight="400"
            py="0.5rem"
            fontSize="md"
          >
            A {hotspot?.experience.name} Hotspot
          </Text>
          {validity && !showInterestedButton ? (
            <AcceptInviteButton onClick={buttonClick} />
          ) : (
            <InterestedButton onClick={buttonClick} />
          )}
        </VStack>
      </VStack>
    </Container>
  );
};

export default HotspotCard;
