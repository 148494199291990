import { Box } from "@chakra-ui/react";
import Header from "@components/Header";
import HotspotCard from "@components/HotspotCard";
import React from "react";

interface HotspotLandingProps {
  setStage?: (stage: number) => void;
  showInterestedButton?: boolean;
}

const HotspotLanding: React.FC<HotspotLandingProps> = ({
  setStage,
  showInterestedButton: showInterestedButton,
}) => {
  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="#101010"
      overflowY="auto"
      sx={{ scrollbarWidth: "none" }}
    >
      <Header />
      <HotspotCard
        setStage={setStage}
        showInterestedButton={showInterestedButton}
      />
    </Box>
  );
};

export default HotspotLanding;
