import { Box, Button } from "@chakra-ui/react";
import { TicketIcon } from "lucide-react";
import React from "react";

interface AcceptInviteButtonProps {
  onClick: (e: React.MouseEvent) => void;
  isFullWidth?: boolean;
}

const AcceptInviteButton: React.FC<AcceptInviteButtonProps> = ({
  onClick,
  isFullWidth = true,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      mx="1rem"
      w={isFullWidth ? "100%" : "auto"}
    >
      <Button
        colorScheme="#ffffff"
        width="full"
        height="3.5rem"
        bgGradient="silver"
        border="1px solid rgba(128, 128, 128, 0.5)"
        background="rgba(16, 16, 16, 0.9)"
        borderRadius="0.5rem"
        leftIcon={
          <Box transform="rotate(-45deg)">
            <TicketIcon size={16} color="white" />
          </Box>
        }
        onClick={onClick}
      >
        Accept Invite
      </Button>
    </Box>
  );
};

export default AcceptInviteButton;
