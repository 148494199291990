import { useGetHotspotFromCode } from "@api/hotspot";
import useHotspotStore from "@stores/hotspot";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import displayToast from "@/utils/toasts";

const useFetchHotspot = (code: string) => {
  const { data, isLoading, isError, error } = useGetHotspotFromCode(code);
  const setHotspot = useHotspotStore((state) => state.setHotspot);
  const setValidity = useHotspotStore((state) => state.setValidity);
  const location = useLocation();
  const containsShare = location.pathname.includes("share");
  const toastMessage = containsShare ? "Hotspot has expired" : "Invite code has expired";


  useEffect(() => {
    if (data) {
      setHotspot(data.hotspot);
      setValidity(data.validity);
      if (!data.validity) {
        displayToast(toastMessage, { type: "error" });
      }
    }
  }, [data, setHotspot, setValidity, toastMessage]);

  return { isLoading, isError, error };
};

export default useFetchHotspot;
