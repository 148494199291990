import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

const getHotspotFromCode = async (code: string) => {
  try {
    const res = await axiosInstance.get(`/hotspots/code/${code}`);
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

const attributeHotspotCode = async (code: string) => {
  try {
    const res = await axiosInstance.post(
      `/hotspots/attribute-hotspot-code/${code}`,
    );
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
  }
};

export const useGetHotspotFromCode = (code: string) => {
  const query = useQuery({
    queryKey: ["hotspot", code],
    queryFn: () => getHotspotFromCode(code),
  });
  return query;
};

export const useAttributeHotspotCode = (code: string) => {
  const mutation = useMutation({
    mutationKey: ["attributeHotspotCode", code],
    mutationFn: () => attributeHotspotCode(code),
  });
  return mutation;
};
