import { Box, HStack, Text } from "@chakra-ui/react";
import Header from "@components/Header";
import HotspotCard from "@components/HotspotCard";
import Spin from "@components/Spinner";
import useFetchHotspot from "@hooks/useFetchHotspot";
import useFetchUser from "@hooks/useFetchUser";
import { WaitlistStatus } from "@interfaces/user";
import { useErrorStore } from "@stores/error";
import useHotspotStore from "@stores/hotspot";
import useUserStore from "@stores/user";
import Auth from "@views/Auth";
import Error from "@views/Error";
import HotspotInfo from "@views/HotspotInfo";
import HotspotLanding from "@views/HotspotLanding";
import OnboardingForm from "@views/OnboardingForm";
import Waitlist from "@views/Waitlist";
import { ChevronRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Invite: React.FC = () => {
  const { code } = useParams() as { code: string };
  const { isLoading, isError: fetchHotspotError } = useFetchHotspot(code);
  const [stage, setStage] = useState(0);
  const validity = useHotspotStore((state) => state.validity);
  const user = useUserStore((state) => state.user);
  const { isError: userError } = useFetchUser();
  const showInterestedButton = [
    WaitlistStatus.ACCEPTED,
    WaitlistStatus.ON_HOLD,
    WaitlistStatus.WAITLIST,
  ].includes(user?.status ?? WaitlistStatus.DRAFT);
  const { setError, isAPIError, setIsAPIError } = useErrorStore();

  useEffect(() => {
    if (fetchHotspotError) {
      setError("Error", "Failed to fetch hotspot");
      setIsAPIError(true);
    }
  }, [fetchHotspotError, setError, setIsAPIError]);

  useEffect(() => {
    if (userError) {
      setError("Error", "Failed to fetch user");
      setIsAPIError(true);
    }
  }, [userError, setError, setIsAPIError]);

  if (isLoading) {
    return <Spin />;
  } else if (isAPIError) {
    return <Error />;
  }

  if (validity) {
    switch (stage) {
      case 0:
        return (
          <HotspotLanding
            setStage={setStage}
            showInterestedButton={showInterestedButton}
          />
        );
      case 1:
        return <HotspotInfo setStage={setStage} />;
      case 2:
        return <Auth setGlobalStage={setStage} code={code} />;
      case 3:
        return <OnboardingForm setStage={setStage} />;
      case 4:
        if (user?.status == WaitlistStatus.ACCEPTED) {
          return (
            <Box
              w="100%"
              h="100%"
              backgroundColor="#101010"
              overflowY="auto"
              sx={{ scrollbarWidth: "none" }}
            >
              <Header />
              <HotspotCard showInterestedButton />
              <Box
                m="1rem"
                background="#FFFFFF05"
                borderTopLeftRadius="1rem"
                borderTopRightRadius="1rem"
                mb="0"
              >
                <HStack px="1rem" py="1.25rem" justifyContent="space-between">
                  <Text fontWeight="700" color="white" ml={1} fontSize="sm">
                    View past Hotspots @8club
                  </Text>
                  <ChevronRight size="24px" color="#FFFFFF52" />
                </HStack>
              </Box>
            </Box>
          );
        }
        return <Waitlist />;
    }
  } else {
    return <HotspotLanding setStage={setStage} showInterestedButton />;
  }
};

export default Invite;
