import bgImage from "@assets/bg_form.png";
import waitlistCard1 from "@assets/waitlist_card_1.png";
import waitlistCard2 from "@assets/waitlist_card_2.png";
import waitlistCard3 from "@assets/waitlist_card_3.png";
import { Box, VStack, Image } from "@chakra-ui/react";
import Header from "@components/Header";
import HotspotDetails from "@components/HotspotDetails";
import WaitlistCard from "@components/WaitlistCard";
import { WaitlistStatus } from "@interfaces/user";
import useUserStore from "@stores/user";
import React from "react";

import DownloadAppButton from "@/buttons/DownloadAppButton";

const Waitlist: React.FC = () => {
  const { user } = useUserStore();
  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="black"
      bgImage={bgImage}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      overflowY="auto"
      sx={{
        scrollbarWidth: "none",
      }}
    >
      <Header />
      <VStack mx="1rem" spacing="1rem" pb="6rem">
        <HotspotDetails isWhite={true} isWaitlistPage={true} />
        {user?.status == WaitlistStatus.WAITLIST && <WaitlistCard />}
        <Image src={waitlistCard1} background="b2" />
        <Image src={waitlistCard2} background="b2" />
        <Image src={waitlistCard3} background="b2" />
      </VStack>
      <Box
        w="100%"
        h="auto"
        position="fixed"
        bottom="0"
        background="#FFFFFF05"
        backdropFilter="auto"
        backdropBlur="8px"
        pt="1.25rem"
        pb="1rem"
      >
        <DownloadAppButton />
      </Box>
    </Box>
  );
};

export default Waitlist;
