import { useAttributeHotspotCode } from "@api/hotspot";
import { useGetOTP } from "@api/otp";
import bgImage from "@assets/bg.png";
import delight_feature from "@assets/delight_feature.png";
import logo from "@assets/logo.png";
import { Box, VStack, Image } from "@chakra-ui/react";
import AuthPhoneNumber from "@components/AuthPhoneNumber";
import HotspotDetails from "@components/HotspotDetails";
import Spin from "@components/Spinner";
import VerifyOTP from "@components/VerifyOTP";
import useFetchUser from "@hooks/useFetchUser";
import { WaitlistStatus } from "@interfaces/user";
import useUserStore from "@stores/user";
import DelightCards from "@views/DelightCards";
import React, { useState, useEffect } from "react";

import displayToast from "@/utils/toasts";

interface AuthProps {
  code: string;
  setGlobalStage: (stage: number) => void;
}
const Auth: React.FC<AuthProps> = ({ setGlobalStage, code }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [delightCards, setDelightCards] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [stage, setStage] = useState(1);
  const { mutate, isError, isSuccess: getOTPSuccess } = useGetOTP(phoneNumber);
  const {
    mutate: attributeHotspotCode,
    isError: attributeCodeError,
    error,
    isSuccess: attributeCodeSuccess,
  } = useAttributeHotspotCode(code);
  const { isLoading, isSuccess, refetch } = useFetchUser();
  const user = useUserStore((state) => state.user);
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useUserStore((state) => state.setIsLoggedIn);

  const handlePhoneNumberChange = (phoneNumber: string) => {
    const phoneNumberPattern = /^[6-9]\d{9}$/;
    if (phoneNumber.length > 10) {
      return;
    }
    setPhoneNumber(phoneNumber);
    setIsNumberValid(phoneNumberPattern.test(phoneNumber));
  };

  const handleSendOTP = () => {
    if (isNumberValid) {
      mutate();
    }
  };

  const handleOTPSuccess = (token: string) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
    refetch();
  };

  useEffect(() => {
    if (isSuccess && !isLoading && user) {
      attributeHotspotCode();
    }
  }, [isSuccess, isLoading, user, attributeHotspotCode]);

  useEffect(() => {
    if (attributeCodeError) {
      if (error?.response?.status === 403) {
        displayToast("You cannot access this invite code", { type: "error" });
        setGlobalStage(
          user?.status === WaitlistStatus.DRAFT ||
            user?.status === WaitlistStatus.REJECTED ||
            !user?.status
            ? 3
            : 4
        );
      } else {
        displayToast("Error fetching details", { type: "error" });
      }
    } else if (attributeCodeSuccess) {
      setGlobalStage(
        user?.status === WaitlistStatus.DRAFT ||
          user?.status === WaitlistStatus.REJECTED ||
          !user?.status
          ? 3
          : 4
      );
    }
  }, [attributeCodeError, error, setGlobalStage, user, attributeCodeSuccess]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (getOTPSuccess) {
      setStage(2);
    }
    if (isError) {
      displayToast("Error sending OTP. Please try again.");
    }
  }, [getOTPSuccess, isError]);

  if (isLoading) {
    return <Spin />;
  } else if (!attributeCodeError && !attributeCodeSuccess && isSuccess) {
    return <Spin />;
  }

  if (delightCards) {
    return <DelightCards setDelightCards={setDelightCards} />;
  }

  if (!isLoggedIn)
    return (
      <Box
        bgImage={bgImage}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        w="100%"
        h="100%"
      >
        <VStack mx="1rem" spacing={8}>
          <VStack pt="1.25rem" spacing={0}>
            <Image src={logo} alt="Logo" onClick={() => setVisibility(true)} />
            <Image
              src={delight_feature}
              alt="Delight Feature"
              visibility={visibility ? "visible" : "hidden"}
              opacity={isLoaded ? 1 : 0}
              transition="opacity 0.5s"
              onClick={() => {
                setVisibility(!visibility);
                setDelightCards(!delightCards);
              }}
            />
          </VStack>

          <VStack spacing={4} w="100%">
            <HotspotDetails />
            {stage === 1 && (
              <AuthPhoneNumber
                phoneNumber={phoneNumber}
                onChanged={handlePhoneNumberChange}
                isNumberValid={isNumberValid}
                onTapVerifyOTP={handleSendOTP}
              />
            )}
            {stage === 2 && (
              <VerifyOTP
                number={phoneNumber}
                resendOTP={handleSendOTP}
                successOTP={handleOTPSuccess}
              />
            )}
          </VStack>
        </VStack>
      </Box>
    );
  else {
    // TODO: Add error page
    return <div>Error</div>;
  }
};

export default Auth;
