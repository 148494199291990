import { UserGender, WaitlistStatus } from "@interfaces/user";
import { create } from "zustand";

interface IUser {
  date_of_birth?: string;
  gender?: UserGender;
  name?: string;
  phone_number?: string;
  status?: WaitlistStatus;
  profile_image_url?: string;
}
interface UserStore {
  user: IUser | undefined;
  photo: string | null | ArrayBuffer;
  isLoggedIn: boolean;
  instaHandle: string;
  setUser: (user: IUser) => void;
  setPhoto: (photo: string | null) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setStatus: (status: WaitlistStatus) => void;
  setInstaHandle: (instaHandle: string) => void;
}
const token = localStorage.getItem("token");

const useUserStore = create<UserStore>((set) => ({
  user: undefined,
  photo: null,
  isLoggedIn: !!token,
  instaHandle: "",
  setUser: (user) => set({ user }),
  setPhoto: (photo) => set({ photo }),
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
  setStatus: (status: WaitlistStatus) => set((state) => ({ user: { ...state.user, status } })),
  setInstaHandle: (instaHandle) => set({ instaHandle }),
}));

export default useUserStore;
